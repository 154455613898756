/* Ensure the la-page container takes up the full height */
.la-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Main content container that expands */
.main-container {
  display: flex;
  flex: 1;
  justify-content: center; /* Center the grid horizontally */
  padding-bottom: 60px;
}

/* Footer specific to LA page */
.la-footer {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #38b6ff;
  color: #010085;
  font-weight: bold;
  font-size: 20px;
  border-top: 1px solid #f2f2f2;
}

.la-container {
  padding: 20px;
  text-align: center;
  width: 70%;
}

.title {
  font-size: 2rem;
  color: #010085;
  margin-bottom: 20px;
  font-weight: bolder;
}

/* Grid layout for legislators */
.legislators-grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(200px, 1fr));
  gap: 30px;
  justify-items: center; /* Center each card horizontally */
  align-items: start; /* Align each card to the top */
}

/* Styling for each legislator card */
.legislator-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  overflow: hidden;
  width: 100%;
  max-height: 60vh;
  max-width: 250px; /* Set max-width for consistency */
}

/* Image styling to ensure it is centered */
.legislator-photo img {
  width: 100%;
  height: auto;
  display: block;
  object-fit: contain; /* Ensure the full image is shown without cropping */
}

/* Center text in info box */
.legislator-info-box {
  width: 100%;
  background-color: #03045e;
  color: white;
  padding: 10px;
  text-align: center;
}

.legislator-info-box h2 {
  font-size: 1.1rem;
  font-weight: 700;
  color: white;
  margin: 0;
}

.legislator-info-box h2 span {
  color: #ffdd00; /* Gold color for last name */
}

.legislator-info-box .role,
.legislator-info-box .course,
.legislator-info-box .school {
  font-size: 0.8rem;
  color: white;
  margin: 2px 0;
}

.role {
  font-weight: bold;
}

.school {
  font-weight: normal;
}

/* Title styling */
.left-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #1a1a1a;
  text-align: center;
  line-height: 1.2;
}

/* Credits styling */
.credits {
  font-size: 0.9rem;
  color: #1a1a1a;
  margin-top: auto;
  text-align: center;
}

/* Mobile styling */
@media (max-width: 768px) {
  .main-container {
    padding: 10px;
  }

  .la-container {
    width: 100%;
  }

  .legislators-grid {
    grid-template-columns: 1fr; /* Stack items vertically */
    gap: 20px; /* Reduced gap between items */
  }

  .legislator-card {
    width: 100%; /* Full width for each card */
    max-width: none; /* Remove max width on mobile */
    max-height: none;
  }

  .title {
    font-size: 1.5rem; /* Slightly smaller title on mobile */
  }

  /* Ensure image takes full width and adjusts properly for mobile */
  .legislator-photo img {
    width: 100%;
    height: auto; /* Automatically adjust height to avoid cropping */
    max-height: none; /* Allow full image height */
    object-fit: contain; /* Ensure the full image is visible without cropping */
  }
}
