/* home.css */
.home {
  display: flex;
  justify-content: center;
  max-width: 90vw;
  margin: 0 auto;
  padding: 20px;
}

.image-container {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Minimal gap between rows */
  width: 100%;
}

.image-row {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.image-wrapper {
  overflow: hidden;
  max-width: 500px; /* Increased max width to make images larger */
  flex: 1;
}

.group-image {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
  margin: 0;
  padding: 0;
}

/* Mobile styling: stack images vertically with minimal gaps */
@media (max-width: 768px) {
  .home {
    max-width: 100vw;
    padding: 10px;
  }

  .image-container {
    gap: 10px; /* Ensure minimal gap between the images */
  }

  .image-row {
    flex-direction: column; /* Stack the images vertically */
    gap: 10px; /* Minimal gap between images */
  }

  .image-wrapper {
    max-width: none; /* Remove max-width on mobile */
    width: 100%; /* Make images take full width on mobile */
  }

  /* Add extra space at the bottom of the container for scrolling */
  .image-container {
    padding-bottom: 20px; /* Adds extra space at the bottom */
  }
}
