/* agenda.css */

.agenda-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.main-container {
    display: flex;
    flex: 1;
}

.left-section {
    width: 30%;
    background-color: #f1f1f1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.left-section img {
    width: 100%;
    height: auto;
}

.agenda-container {
    max-width: 70%;
    padding: 20px;
}

.title {
    font-size: 2.5rem;
    color: #010085;
    font-weight: bold;
    margin-bottom: 1rem;
    text-align: left;
}

/* Committees grid styling */
.committees-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-bottom: 2rem;
    overflow-x: auto;
    overflow-y: hidden;
}

.committee-card {
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
}

.committee-card h2 {
    font-size: 1.2rem;
    color: #010085;
    border-bottom: 2px solid #010085;
    padding-bottom: 5px;
    margin-bottom: 10px;
}

.committee-card p {
    font-size: 0.9rem;
    color: #333;
}

/* Intro text */
.intro-text {
    font-size: 1rem;
    color: #333;
    margin-bottom: 2rem;
    padding-bottom: 10px;
}

/* Agenda grid and cards */
.agenda-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
}

.agenda-card {
    padding: 20px; /* Match padding with committee-card */
    border: 1px solid #e0e0e0; /* Same border style */
    border-radius: 5px; /* Same border radius */
    background-color: white; /* Make the background consistent */
}

.agenda-card h2 {
    font-size: 1.2rem; /* Match font size with committee-card h2 */
    color: #010085; /* Use the same color */
    border-bottom: 2px solid #010085; /* Add border under heading like committee-card */
    padding-bottom: 5px; /* Add padding under heading for consistency */
    margin-bottom: 10px; /* Match margin with committee-card */
}

.agenda-card p {
    font-size: 0.9rem; /* Match font size with committee-card p */
    color: #333; /* Use the same color */
}


/* Pagination styling */
.pagination {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 30px;
}

.pagination button {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #38b6ff;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-weight: bold;
}

.pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* Footer styling */
.agenda-footer {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #38b6ff;
    color: #010085;
    font-weight: bold;
    font-size: 20px;
}
