@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}

.base {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
}

* {
  margin: 0;
  font-family: "Quicksand";
  color: #333;
}

.navbar {
  padding: 20px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  border-bottom: 1px solid #f2f2f2;
  background-color: #abd2fa;
  justify-content: flex-start;
  flex-wrap: nowrap;
  overflow: visible; /* Allow the dropdown to be visible */
}

.navbar .santulogo {
  width: 30px;
  height: 30px;
}

.navbar .links {
  margin-left: auto;
  margin-right: 35px;
  display: flex;
  align-items: center;
  position: relative;
  gap: 15px; /* Adjusted spacing for desktop */
}

.navbar a {
  text-decoration: none;
  padding: 6px 10px;
  color: #010085;
  font-weight: bold;
  font-size: 20px;
}

.navbar a:hover {
  background-color: #ffc300;
  color: #f2f2f2;
  border-radius: 10px;
  border: 1px solid #ffc300;
}

.la-item {
  position: relative;
}

.la-dropdown {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  right: 0;
  width: 300px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  z-index: 1000;
  transform: translateY(-10px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.la-item:hover .la-dropdown {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
}

.la-dropdown a {
  display: block;
  padding: 10px;
  color: #010085;
  font-weight: bold;
  text-decoration: none;
}

.la-dropdown a:hover {
  border-radius: 10px;
}

.footer {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 2%;
  margin: 0 auto;
  border-top: 1px solid #f2f2f2;
  background-color: #38b6ff;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
  flex-shrink: 0;
}

.footer .socials {
  padding-left: 10px;
  padding-right: 10px;
}

.footer .fblogo {
  width: 30px;
  height: 30px;
}

.footer .nameS {
  color: #010085;
  font-weight: bold;
  font-size: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.base {
  align-items: stretch;
  flex-basis: 100%;
  width: 100%;
  height: 100vh;
  background-color: #fffcf0;
}

.baseFlex {
  display: flex;
  width: 100%;
  border-bottom: #010085;
  border-bottom: 50px solid #007eff;
}

.vision {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.eb-column {
  width: 60%;
}

.eb-column-bottom {
  width: 60%;
  height: auto;
  background: linear-gradient(to bottom, #ffffff, #abd2fa);
}

.baseEb-bottom {
  width: 100%;
  height: 100%;
}

.eb-column img {
  width: 100%;
  height: auto;
  display: block;
}

.block1 {
  height: auto;
}

.baseEb {
  width: 100%;
  height: auto;
}

.vis1 {
  font-style: bold;
  color: #010085;
  font-size: 40px;
}

.vis2 {
  color: #50a5fd;
  font-size: 55px;
}

.visionWords h1 {
  margin-block-start: -0.3em;
}

.baseSpeech {
  font-size: clamp(1rem, 2vw + 1rem, 1.5rem); /* Dynamic font size based on viewport */
  line-height: 1.2; /* Keeps text spacing consistent */
  color: #010085;
  margin-bottom: 0.5rem;
  word-wrap: break-word; /* Prevents long words from causing overflow */
}

.baseSpeechContainer {
  padding: 2vw;
  max-width: 90vw;
  max-height: 65vh; /* Limits height to prevent overflow */
  overflow-y: auto; /* Adds scrolling if text exceeds container */
}

.collegesFlex {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.threeC, .twoC {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 5px;
}

.threeC img, .twoC img {
  max-width: 350px;
  width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .navbar {
    padding: 10px;
    flex-direction: row; /* Keep items in a row */
    overflow-x: auto; /* Allow scrolling */
    overflow-y: visible; 
  }

  .navbar .links {
    flex-direction: row;
    gap: 15px; /* Ensure enough space between items */
  }

  .navbar a {
    font-size: 18px; /* Smaller font for mobile */
  }

  .base {
    flex-direction: column;
    padding: 1vw;
  }

  .baseFlex {
    flex-direction: column;
    padding: 1vw;
    gap: 10px;
  }

  .vision, .eb-column, .eb-column-bottom {
    width: 100%;
    text-align: center;
  }

  .visionWords h1 {
    font-size: clamp(1.5rem, 5vw, 3rem);
  }

  .baseEb, .baseEb-bottom, .eb-column img {
    width: 90%;
    margin: 0 auto;
  }

  .baseSpeechContainer {
    max-width: 100vw;
    padding: 5vw;
  }

  .baseSpeech {
    font-size: clamp(1rem, 4vw, 1.2rem);
    line-height: 1.3;
  }

  /* Footer responsive changes */
  .footer {
    flex-direction: row; /* Keep items in a row for mobile */
    justify-content: center; /* Center the items horizontally */
    padding: 20px;
    height: auto; /* Allow footer to expand as needed */
    align-items: center; /* Vertically align items */
  }

  .footer .socials {
    margin-left: 5px; /* Reduced margin to bring logo closer */
    margin-right: 5px; /* Optional: reduce space after the logo */
  }

  .footer .nameS {
    font-size: 16px; /* Adjust font size for smaller screens */
    padding-left: 5px; /* Reduced padding for closer alignment */
    padding-right: 5px; /* Reduced padding for closer alignment */
  }

  .footer .fblogo {
    width: 25px;
    height: 25px;
  }
}
