/* Global Reset for all elements */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
}

/* Eb component styling */
.eb {
  font-family: Arial, sans-serif;
  margin-bottom: 20px;
  padding: 0;
}

/* Full-Width Banner Section */
.banner {
  position: relative;
  width: 100vw;
  margin-left: calc(-50vw + 50%); /* Center-align the full-width banner */
  margin-top: 0; /* Ensure there's no top margin */
  padding: 0; /* Remove padding from the banner container */
}

.banner-image {
  width: 100vw;
  height: auto;
  display: block;
}

.banner::after {
  content: "";
  display: block;
  height: 50px; /* Adjust thickness of border as needed */
  background-color: #007eff; /* Choose the border color */
}

/* Content Section */
.content-text{
  padding: 40px 20px;
  background-color: #F5F5F5;
  text-align: center;
  
}

.section-title {
  font-size: clamp(2rem, 8vw, 4em); /* Responsive font size */
  font-weight: bold;
  color: #0e2391;
}


.description {
  font-size: 1.1em;
  color: #333;
  margin: 20px 0;
}

/* Highlighted Thrusts Title */
.thrusts-title {
  font-size: 2.5em;
  font-weight: bold;
  color: #03045e;
  margin: 30px 0 10px;
}

.thrusts-title span {
  background-color: #FBC02D; /* Background highlight */
  padding: 2px;
  border-radius: 5px;
}

/* Thrusts Section */
.thrusts {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.thrust {
  max-width: 300px;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  max-height: 40vh;
}

.thrust-heading {
  font-size: 1.2em;
  color: #03045e;
  margin-bottom: 10px;
  position: relative;
}

.thrust-heading::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 270px; /* Adjust width as needed */
  height: 2px;
  background-color: #2D4F91; /* Color of the line below headings */
}

.thrust p {
  font-size: 1em;
  color: #666;
}

/* Highlighted SPOA Title */
.spoa-title {
  font-size: 2.5em;
  font-weight: bold;
  color: #03045e;
  margin: 30px 0 10px;
}

.spoa-title span {
  background-color: #FBC02D; /* Background highlight */
  padding: 2px;
  border-radius: 5px;
}

/* Thrusts Section */
.spoas {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.spoa {
  max-width: 300px;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  max-height: 45vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

.spoa-heading {
  font-size: 1.2em;
  color: #03045e;
  margin-bottom: 10px;
  position: relative;
}

.spoa-heading::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 270px; /* Adjust width as needed */
  height: 2px;
  background-color: #2D4F91; /* Color of the line below headings */
}

.spoa p {
  font-size: 1em;
  color: #666;
}

.navigation-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.navigation-buttons button {
  padding: 8px 16px;
  background-color: #007eff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.navigation-buttons button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}


